import React, {useEffect, useRef, useState} from 'react';
import { Rnd } from 'react-rnd';
import {Button, Tooltip} from 'antd';
import { ArrowsAltOutlined, CloseOutlined, ShrinkOutlined, LoadingOutlined } from "@ant-design/icons";

// Services
import CRMService from "src/services/CRMService";

// Components
import IconBase from "src/components/IconBase";
import LoadingText from "./LoadingText";

export const formatTime = (seconds) => {
    const hours = String(Math.floor(seconds / 3600)).padStart(2, '0');
    const minutes = String(Math.floor((seconds % 3600) / 60)).padStart(2, '0');
    const secs = String(seconds % 60).padStart(2, '0');
    return `${hours}:${minutes}:${secs}`;
};

const getBrowser = () => {
    const userAgent = navigator.userAgent;
    if (userAgent.includes("Chrome") && !userAgent.includes("Edg")) return "Chrome";
    if (userAgent.includes("Firefox")) return "Firefox";
    if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) return "Safari";
    if (userAgent.includes("Edg")) return "Edge";
    return "Unknown";
};

const renderBrowserInstructions = () => {
    const instructions = {
        Chrome: "Bạn chưa cấp quyền truy cập microphone trên Google Chrome. Vui lòng nhấn vào biểu tượng ổ khóa bên cạnh thanh địa chỉ, chọn Cài đặt trang, sau đó chọn Microphone và chuyển thành Cho phép, rồi tải lại trang.",
        Firefox: "Bạn chưa cấp quyền truy cập microphone trên Mozilla Firefox. Vui lòng nhấn vào biểu tượng ổ khóa bên cạnh thanh địa chỉ, chọn Microphone và chuyển thành Cho phép, rồi tải lại trang.",
        Safari: "Bạn chưa cấp quyền truy cập microphone trên Safari. Vui lòng mở Safari, chọn Tùy chọn, vào tab Trang web, chọn Microphone và chuyển thành Cho phép, rồi tải lại trang.",
        Edge: "Bạn chưa cấp quyền truy cập microphone trên Microsoft Edge. Vui lòng nhấn vào biểu tượng ổ khóa bên cạnh thanh địa chỉ, chọn Cài đặt quyền trang web, chọn Microphone và chuyển thành Cho phép, rồi tải lại trang."
    };
    return instructions[getBrowser()] || "Bạn chưa cấp quyền truy cập microphone. Vui lòng kiểm tra cài đặt trình duyệt và cấp quyền cho microphone.";
};

const handleMicrophoneError = (error) => {
    if (error.name === 'NotAllowedError') return renderBrowserInstructions();
    if (error.name === 'NotFoundError') return "Không tìm thấy thiết bị microphone. Vui lòng kiểm tra lại kết nối hoặc đảm bảo rằng thiết bị của bạn có microphone hoạt động.";
    return error.message;
};

const CALL_STATE = {
    INIT: 'init',
    RINGING: 'ringing',
    CONNECTED: 'connected',
    ENDED: 'ended',
    NO_RESPONSE: 'noResponse',
}

const WIDTH = 600;
const HEIGHT = 300;

const ZenifyApp = window.ZenifyApp || {}
const ZenifyConfig = window.ZenifyConfig || {}

const Index = (props) => {
    const { phoneNumber, setIsComponentVisible } = props

    const [isExtend, setIsExtend] = useState(true)
    const [position, setPosition] = useState({ x: 0, y: 0 })
    const [size, setSize] = useState({ width: WIDTH, height: HEIGHT })
    const [microphoneError, setMicrophoneError] = useState('')

    const [enableMicro, setEnableMicro] = useState(true)
    const [loading, setLoading] = useState(true)
    const [zenifyError, setZenifyError] = useState(false)
    const [timeElapsed, setTimeElapsed] = useState(0)

    const [callState, setCallState] = useState(CALL_STATE.INIT)
    const [callID, setCallID] = useState()
    const prevCallStateRef = useRef([]);

    useEffect(() => {
        let timer = null;

        if (callState === CALL_STATE.CONNECTED) {
            setTimeElapsed(0);
            timer = setInterval(() => {
                setTimeElapsed((prev) => prev + 1);
            }, 1000);
        }

        return () => {
            clearInterval(timer);
        };
    }, [callState]);


    useEffect(() => {
        if (typeof window.ZenifyApp === 'undefined') {
            setZenifyError(true)
            setLoading(false)
            return
        }

        const requestMicrophoneAccess = async () => {
            try {
                await navigator.mediaDevices.getUserMedia({ audio: true })
                setMicrophoneError('')
            } catch (error) {
                setMicrophoneError(handleMicrophoneError(error));
            } finally {
                setTimeout(() => setLoading(false), 200)
            }
        };
        requestMicrophoneAccess().then()

        return () => {
            prevCallStateRef.current = []
        }
    }, [])

    useEffect(() => {
        const centerPosition = () => {
            const newX = isExtend ? (window.innerWidth - size.width) / 2 : window.innerWidth - size.width - 20
            const newY = isExtend ? (window.innerHeight - size.height) / 3 : 20
            setPosition({ x: newX, y: newY })
        };
        centerPosition()
        window.addEventListener('resize', centerPosition)
        return () => window.removeEventListener('resize', centerPosition)
    }, [isExtend, size])

    useEffect(() => {
        if (!loading && !zenifyError && !microphoneError) {
            ZenifyApp.subscribe(onAppEvent)
            dialCall(phoneNumber)
        }
    }, [loading, zenifyError, microphoneError])

    const onCallLog = () => {
        const lastStatusCall = prevCallStateRef.current[prevCallStateRef.current.length - 1]

        if (lastStatusCall.length === 0) return

        CRMService.callLog({ phone: phoneNumber, status: lastStatusCall, time: timeElapsed })
            .then(() => {
                console.log('Ghi log thành công')
            })
            .catch(err => {
                console.error('Ghi log thất bại:', err)
            });
    };

    const handleStatusAndSizeRnd = () => {
        setIsExtend(prev => {
            setSize({width: WIDTH, height: HEIGHT});
            return true;
        });
    }

    useEffect(() => {
        if (callState === CALL_STATE.ENDED || callState.NO_RESPONSE) {
            onCallLog(callID)
        }
    }, [callState]);

    const onAppEvent = msg => {
        console.log("CALL_EVENT_CODE_DEFAULT", msg)
        switch (msg.objectId) {
            case ZenifyConfig.RING_AGENT_RESPOND_ID:
                setCallState(CALL_STATE.RINGING)
                prevCallStateRef.current.push(CALL_STATE.RINGING)
                break
            case ZenifyConfig.AGENT_ANSWER_RESPOND_ID:
                setCallState(CALL_STATE.CONNECTED)
                prevCallStateRef.current.push(CALL_STATE.CONNECTED)
                setIsExtend(prev => {
                    setSize({ width: 300, height: 55 });
                    return false;
                });
                break
            case ZenifyConfig.OUTCALL_ERROR_RESPONSE_ID:
            case ZenifyConfig.AGENT_END_CALL_RESPOND_ID:
                setCallState(CALL_STATE.ENDED)
                handleStatusAndSizeRnd()
                break
            case ZenifyConfig.END_CALL_RESPOND_ID:
                setCallState(CALL_STATE.NO_RESPONSE)
                handleStatusAndSizeRnd()
                break
            default:
                console.log("CALL_EVENT_CODE", msg.objectId)
                break
        }
    }

    const dialCall = phoneNumber => {
        ZenifyApp.activateVoice()
        ZenifyApp.dialCall(phoneNumber)
    }

    const terminate = () => {
        if (callState === CALL_STATE.INIT) {
            setIsComponentVisible(false)
            return
        }
        ZenifyApp.terminate()
    }

    const mute = () => {
        setEnableMicro(false)
        ZenifyApp.mute()
    }

    const unMute = () => {
        setEnableMicro(true)
        ZenifyApp.unmute()
    }

    const toggleExtend = () => {
        setIsExtend(prev => {
            setSize(prev ? { width: 300, height: 55 } : { width: WIDTH, height: HEIGHT });
            return !prev;
        });
    };

    const onHandleMicro = () => {
        enableMicro ? mute() : unMute()
    }

    // render
    const renderTimer = (isExtend = true) => {
        return (
            <div style={{ display: 'flex', flexDirection: isExtend ? 'column' : 'row', justifyContent: 'center' }}>
                <div style={{ fontWeight: 'bolder', padding: isExtend ? '5px 0' : 'unset' }}>
                    {isExtend ? 'Thời lượng cuộc gọi' : 'Thời lượng: '}
                </div>
                <div style={{ marginTop: isExtend ? '5px' : '0', marginLeft: isExtend ? '0' : '5px' }}>
                    {formatTime(timeElapsed)}
                </div>
            </div>
        );
    };

    const renderContentCall = () => {
        let comp
        switch (callState) {
            case CALL_STATE.INIT:
            case CALL_STATE.RINGING:
            case CALL_STATE.CONNECTED:
                comp = (
                    <div style={{display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between'}}>
                        <div style={{
                            margin: '40px 20px',
                            textAlign: 'center',
                            minHeight: '50px',
                            height: '60px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            {callState === CALL_STATE.INIT && <LoadingText text="Đang kết nối"/>}
                            {callState === CALL_STATE.RINGING && <LoadingText text="Đang đổ chuông"/>}
                            {callState === CALL_STATE.CONNECTED && renderTimer()}
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center', padding: '40px 0'}}>
                            <Tooltip title={enableMicro ? "Tắt micro" : "Bật micro"}>
                                <Button
                                    style={{
                                        margin: '0 10px',
                                        backgroundColor: enableMicro ? '#0a8bc2' : '#D3D3D3',
                                        borderColor: enableMicro ? '#0a8bc2' : '#D3D3D3',
                                        outline: 'none',
                                        boxShadow: 'none',
                                        pointerEvents: callState === CALL_STATE.CONNECTED ? 'auto' : 'none',
                                    }}
                                    onClick={onHandleMicro}
                                    type="primary"
                                    shape="circle"
                                    icon={<IconBase size="medium" name={enableMicro ? "mic" : "muteMic"} color={'white'}/>}
                                    size={'large'}
                                />
                            </Tooltip>
                            <Tooltip title={'Kết thúc cuộc gọi'}>
                                <Button
                                    style={{
                                        margin: '0 10px',
                                        backgroundColor: '#FF4D4F',
                                        outline: 'none',
                                        boxShadow: 'none'
                                    }}
                                    onClick={terminate}
                                    type="primary"
                                    shape="circle"
                                    icon={<IconBase size="medium" name="phone" color={'white'}/>}
                                    size={'large'}
                                />
                            </Tooltip>
                        </div>
                    </div>
                )
                break
            case CALL_STATE.ENDED:
            case CALL_STATE.NO_RESPONSE:
                comp = (
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '30px 0px'}}>
                        {callState === CALL_STATE.ENDED && (
                            <>
                                <div style={{fontWeight: 'bolder', fontSize: '18px'}}>Cuộc gọi đã kết thúc</div>
                                <div style={{
                                    fontWeight: 'bolder',
                                    padding: '15px 0'
                                }}>{`Thời lượng cuộc gọi: ${formatTime(timeElapsed)}`}</div>
                            </>
                        )}
                        {callState === CALL_STATE.NO_RESPONSE && (
                            <div style={{fontWeight: 'bolder', fontSize: '18px'}}>Người nhận không nhấc máy</div>
                        )}
                        <div style={{display: 'flex', paddingTop: '40px'}}>
                            <div style={{padding: '0 10px'}}>
                                <Button
                                    style={{
                                        margin: '0 8px',
                                        backgroundColor: '#D3D3D3',
                                        borderColor: '#D3D3D3',
                                        outline: 'none',
                                        boxShadow: 'none'
                                    }}
                                    onClick={() => setIsComponentVisible(false)}
                                    type="primary"
                                    shape="circle"
                                    icon={<CloseOutlined color={'white'}/>}
                                    size={'large'}
                                />
                                <div style={{fontWeight: 'bold', textAlign: 'center', marginTop: '5px'}}>Đóng</div>
                            </div>
                            <div style={{padding: '0 8px'}}>
                                <Button
                                    style={{
                                        margin: '0 10px',
                                        backgroundColor: '#1890ff',
                                        outline: 'none',
                                        boxShadow: 'none'
                                    }}
                                    onClick={() => {
                                        setCallState(CALL_STATE.INIT)
                                        dialCall(phoneNumber)
                                    }}
                                    type="primary"
                                    shape="circle"
                                    icon={<IconBase size="medium" name="phone" color={'white'}/>}
                                    size={'large'}
                                />
                                <div style={{fontWeight: 'bold', textAlign: 'center', marginTop: '5px'}}>Gọi lại</div>
                            </div>
                        </div>
                    </div>
                )
                break
            default:
                comp = null
                break
        }
        return (
            <div style={{cursor: isExtend ? "unset" : "move", width: '100%', height: '100%'}}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: isExtend ? 'unset' : 'center',
                    cursor: isExtend ? 'unset' : 'move',
                    height: '100%'
                }}>
                    <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                        {!isExtend && (
                            <div style={{
                                flex: 1,
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}>
                                {renderTimer(false)}
                                <div style={{paddingRight: '8px'}}>
                                    <Tooltip title={enableMicro ? "Tắt micro" : "Bật micro"}>
                                        <Button
                                            style={{
                                                margin: '0 5px',
                                                backgroundColor: enableMicro ? '#0a8bc2' : '#D3D3D3',
                                                borderColor: enableMicro ? '#0a8bc2' : '#D3D3D3',
                                                outline: 'none',
                                                boxShadow: 'none'
                                            }}
                                            onClick={onHandleMicro}
                                            type="primary"
                                            shape="circle"
                                            icon={<IconBase size="medium" name={enableMicro ? "mic" : "muteMic"} color={'white'} />}
                                            size={'middle'}
                                        />
                                    </Tooltip>
                                    <Tooltip title={'Kết thúc cuộc gọi'}>
                                        <Button
                                            style={{
                                                margin: '0 5px',
                                                backgroundColor: '#FF4D4F',
                                                outline: 'none',
                                                boxShadow: 'none'
                                            }}
                                            onClick={() => {
                                                terminate()
                                            }}
                                            type="primary"
                                            shape="circle"
                                            icon={<IconBase size="medium" name="phone" color={'white'}/>}
                                            size={'middle'}
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                        )}
                        <div onClick={toggleExtend} style={{cursor: 'pointer', padding: '2px 6px', pointerEvents: callState === CALL_STATE.CONNECTED ? 'auto' : 'none'}} >
                            {isExtend ? <ShrinkOutlined style={{fontSize: '20px', opacity: callState === CALL_STATE.CONNECTED ? 1 : 0.5 }} /> :
                                <ArrowsAltOutlined style={{fontSize: '20px'}}/>}
                        </div>
                    </div>
                    {isExtend && comp}
                </div>
            </div>
        )
    }

    const renderContent = () => {
        if (zenifyError) {
            return (
                <div style={{textAlign: 'center', fontWeight: 'bolder', margin: '50px 0', padding: '20px' }}>
                    Đã xảy ra lỗi: Zenify không được tải. Vui lòng liên hệ với quản trị viên.
                </div>
            );
        }
        return renderContentCall()
    };

    return (
        <div id="calling" style={{position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, zIndex: 1000, pointerEvents: 'none'}}>
            <Rnd
                position={position}
                size={size}
                // disableDragging={isExtend}
                enableResizing={false}
                onDragStop={(e, d) => setPosition({x: d.x, y: d.y})}
                onDragStart={(e) => isExtend && e.preventDefault()}
                style={{
                    border: '1px solid #007bff',
                    borderRadius: '10px',
                    boxShadow: '0 8px 20px rgba(0, 123, 255, 0.5)',
                    backgroundColor: '#f9f9f9',
                    padding: '10px',
                    position: 'relative',
                    pointerEvents: 'auto',
                }}
            >
                {loading ? (
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                        <LoadingOutlined style={{fontSize: '40px', color: '#007bff'}} spin/>
                    </div>
                ) : microphoneError ? (
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                            <div onClick={() => setIsComponentVisible(false)} style={{cursor: 'pointer', padding: '2px 6px'}}>
                                <CloseOutlined style={{fontSize: '20px'}}/>
                            </div>
                        </div>
                        <div style={{textAlign: 'center', fontWeight: 'bolder', margin: '50px 0', padding: '20px'}}>
                            {microphoneError}
                        </div>
                    </div>
                ) : renderContent()}
            </Rnd>
        </div>
    );
};

export default Index;
