import React, { useState, useEffect } from 'react';

const BlinkingDot = ({ size = 15, timer = 1000, style }) => {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            setVisible(v => !v);
        }, timer);

        return () => clearInterval(interval);
    }, []);

    return (
        <div
            style={{
                width: `${size}px`,
                height: `${size}px`,
                borderRadius: '50%',
                backgroundColor: 'red',
                opacity: visible ? 1 : 0.3,
                transition: 'opacity 0.5s',
                ...style
            }}
        />
    );
};

export default BlinkingDot;
