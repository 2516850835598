import React, {useEffect, useState} from 'react';
import Button from "src/components/MyButton/Button";
import {PhoneOutlined} from "@ant-design/icons";
import {Avatar} from "antd";
import LoadingText from "../../components/Calling/LoadingText";
import {formatTime} from "../../components/Calling";
import IconBase from "src/components/IconBase";
import BlinkingDot from "src/components/BlinkingDot";

Calling.propTypes = {

};

const CALL_STATE = {
    NOT_STATUS: 'NOT_STATUS',
    INIT: 'init',
    RINGING: 'ringing',
    CONNECTED: 'connected',
    ENDED: 'ended',
    NO_RESPONSE: 'noResponse',
}

const ZenifyApp = window.ZenifyApp || {}
const ZenifyConfig = window.ZenifyConfig || {}

function Calling(props) {
    const { phoneNumber } = props

    const [callState, setCallState] = useState(CALL_STATE.NOT_STATUS)
    const [timeElapsed, setTimeElapsed] = useState(0)
    const [enableMicro, setEnableMicro] = useState(true)

    const dialCall = phoneNumber => {
        ZenifyApp.activateVoice()
        ZenifyApp.dialCall(phoneNumber)
    }

    const terminate = () => {
        ZenifyApp.terminate()
    }

    const handleButtonClick = () => {
        if (callState === CALL_STATE.NOT_STATUS) {
            setCallState(CALL_STATE.INIT)
            dialCall(phoneNumber)
            return
        }
        terminate()
    }

    const onAppEvent = msg => {
        console.log("CALL_EVENT_CODE_DEFAULT", msg)
        switch (msg.objectId) {
            case ZenifyConfig.RING_AGENT_RESPOND_ID:
                setCallState(CALL_STATE.RINGING)
                break
            case ZenifyConfig.AGENT_ANSWER_RESPOND_ID:
                setCallState(CALL_STATE.CONNECTED)
                break
            case ZenifyConfig.OUTCALL_ERROR_RESPONSE_ID:
            case ZenifyConfig.AGENT_END_CALL_RESPOND_ID:
                // setCallState(CALL_STATE.ENDED)
                setCallState(CALL_STATE.NOT_STATUS)
                break
            case ZenifyConfig.END_CALL_RESPOND_ID:
                // setCallState(CALL_STATE.NO_RESPONSE)
                setCallState(CALL_STATE.NOT_STATUS)
                break
            default:
                console.log("CALL_EVENT_CODE", msg.objectId)
                break
        }
    }

    useEffect(() => {
        ZenifyApp.subscribe(onAppEvent)
    }, [])

    useEffect(() => {
        let timer = null;

        if (callState === CALL_STATE.CONNECTED) {
            setTimeElapsed(0);
            timer = setInterval(() => {
                setTimeElapsed((prev) => prev + 1);
            }, 1000);
        }

        return () => {
            clearInterval(timer);
        };
    }, [callState]);

    const mute = () => {
        setEnableMicro(false)
        ZenifyApp.mute()
    }

    const unMute = () => {
        setEnableMicro(true)
        ZenifyApp.unmute()
    }

    const onHandleMicro = () => {
        enableMicro ? mute() : unMute()
    }

    const renderCalling = (isExtend = true) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}>
                <BlinkingDot size={10} style={{ marginBottom: '2px' }} />
                <div style={{ margin: '0 5px' }}>
                    {formatTime(timeElapsed)}
                </div>
                <Button
                    style={{
                        padding: '0 10px',
                        backgroundColor: "unset",
                        outline: 'none',
                        boxShadow: 'none',
                    }}
                    onClick={onHandleMicro}
                    type="primary"
                    shape="circle"
                    icon={<IconBase size="medium" name={enableMicro ? "mic" : "muteMic"} color={'#0a8bc2'}/>}
                    size={'large'}
                />
            </div>
        );
    };

    const renderContent = () => {
        return (
            <div>
                {callState === CALL_STATE.INIT && <LoadingText text="Đang kết nối"/>}
                {callState === CALL_STATE.RINGING && <LoadingText text="Đang đổ chuông"/>}
                {callState === CALL_STATE.CONNECTED && renderCalling()}
            </div>
        )
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center', padding: '0 10px' }}>
            {renderContent()}
            <Avatar
                onClick={handleButtonClick}
                icon={<PhoneOutlined
                    disabled={callState === CALL_STATE.INIT}
                    style={{
                        fontSize: "20px",
                        color: callState === CALL_STATE.NOT_STATUS ? '#0a8bc2' : 'red' }} />}
                style={{ backgroundColor: "transparent", color: "#1890ff", fontSize: 18 }}
            />
        </div>
    );
}

export default Calling;