import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import HRService from "../services/HumanResource";
import {DATE_FORMAT_SLASH, momentDateToString} from "../lib/dateFormatters";
import {INTENDED_USE} from "../pages/ADMIN/Bussiness/Contact";
import {formatCurrency} from "../pages/ADMIN/Bussiness/Contact/components/ContactCustomer";

export const fetchContactList = createAsyncThunk(
    'crm/listContactMobile',
    async (payload) => {
        const response = await HRService.getContactList(payload)
        const { success } = response || {}
        return success
    }
);

const contactSlice = createSlice({
    name: 'crmMobile',
    initialState: {
        dataContact: [],
        loading: false,
        error: null,
        total: 0,
        statistical: {},
    },
    reducers: {
        updateStatus: (state, action) => {
            const { contactId, status, desc } = action.payload;
            state.dataContact = status === 4
                ? state.dataContact.filter(item => item.code_contact !== contactId)
                : state.dataContact.map(item =>
                    item.code_contact === contactId ?
                        {
                            ...item,
                            status,
                            desc,
                            updated_at: momentDateToString(new Date(), DATE_FORMAT_SLASH)
                        } : item
                );
            state.dataContact.sort((a, b) => a.status - b.status);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchContactList.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchContactList.fulfilled, (state, action) => {
                state.loading = false;
                state.total = action.payload.total;
                state.statistical = action.payload.statistical;

                const newData = Array.isArray(action.payload.data)
                    ? action.payload.data.map(item => ({
                        code_contact: item.ID || "",
                        contact_name: item.full_name || "",
                        status: item.status,
                        phone: item.phone_number,
                        date_create: momentDateToString(item.created_at, DATE_FORMAT_SLASH),
                        updated_at: momentDateToString(item.updated_at, DATE_FORMAT_SLASH),
                        person_create: item?.personnel?.name || '',
                        expiry_date: momentDateToString(item.renewal_date, DATE_FORMAT_SLASH),
                        plate: item.license_plate,
                        vehicles_brand: item.brand,
                        vehicles_type: item.vehicle_type,
                        vehicles_chassis: item.chassis_number,
                        vehicles_engine: item.engine_number,
                        vehicles_tonnage: item.load_capacity,
                        vehicles_seat_capacity: item.number_of_seats,
                        intended_use: INTENDED_USE[item.usage_purpose]?.title || '',
                        city: item?.provinces?.title || '',
                        address: item.address,
                        assign: item?.assigned?.personnel?.name || '',
                        desc: item?.desc || '',
                        shipping_address: item?.shipping_address || '',
                        price: item?.price ? formatCurrency(parseFloat(item?.price)?.toString()) : '',
                    })) : [];

                if (action.meta.arg.page === 1) {
                    // Trang đầu -> reset danh sách
                    state.dataContact = newData;
                } else {
                    // Các trang tiếp theo -> nối thêm dữ liệu
                    state.dataContact = [...state.dataContact, ...newData];
                }
            })
            .addCase(fetchContactList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const { updateStatus, setListProvince  } = contactSlice.actions


export default contactSlice.reducer;
