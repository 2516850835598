import { configureStore } from "@reduxjs/toolkit"
import appGlobalReducer from "./appGlobal"
import commonReducer from "./common"
import roleReducer from "./role"
import hrReducer from "./hr"
import crmReducer from "./crm"
import crmMobileReducer from "./crmMobile"
export default configureStore({
  reducer: {
    appGlobal: appGlobalReducer,
    role: roleReducer,
    common: commonReducer,
    hr: hrReducer,
    crm: crmReducer,
    crmMobile: crmMobileReducer,
  },
})

