import React from 'react';
import { Drawer } from 'antd';

const SIZE = {
    full: '100%',
    large: '90%',
    medium: '60%',
    small: '40%',
};

function Index(props) {
    const { children, size = 'large', destroyOnClose = true, placement = 'right' } = props;
    return (
        <Drawer className="drawer-custom" {...props} placement={placement} width={SIZE[size]} destroyOnClose={destroyOnClose}>
            {children}
        </Drawer>
    );
}

export default Index;
