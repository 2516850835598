import React, {useEffect, useState, useCallback, useMemo} from "react";
import { FilterOutlined, PhoneOutlined } from "@ant-design/icons";
import { fetchContactList } from "src/redux/crmMobile";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, List, Select, Spin } from "antd";
import ContactCustomer from "../ContactCustomer";
import Filter from "../Filter";
import {maskPhoneNumber} from "../../components/ContactCustomer";

const TYPE_CONTACT = [
    { name: "Tất cả", value: "all" },
    { name: "Chưa assign", value: "not_assigned" },
    { name: "Đã assign", value: "assigned" },
    { name: "Tái tục", value: "renewal" },
    { name: "Thùng rác", value: "archive" },
];

function Index() {
    const [type, setType] = useState("all");
    const [openFilter, setOpenFilter] = useState(false);
    const [filter, setFilter] = useState({});
    const [isIdDrawerContactDetail, setIsIdDrawerContactDetail] = useState(null);
    const [page, setPage] = useState(1);
    const [isFetching, setIsFetching] = useState(false);

    const dispatch = useDispatch();
    const { dataContact, statistical, total, loading } = useSelector(
        (state) => state.crmMobile
    );

    useEffect(() => {
        setPage(1);
    }, [filter, type]);

    const getListContact = useCallback(
        (newPage) => {
            if (isFetching) return;

            setIsFetching(true);
            const payload = {
                filter,
                type,
                page: newPage,
                per_page: 20,
            };
            if (type === "all") delete payload.type;
            if (Object.keys(filter).length === 0) delete payload.filter;

            dispatch(fetchContactList(payload)).finally(() => {
                setIsFetching(false);
            });
        },
        [dispatch, filter, type]
    );

    useEffect(() => {
        if (!isFetching) {
            getListContact(page);
        }
    }, [filter, type, page]);

    useEffect(() => {
        const container = document.querySelector(".container_mobile");
        if (!container) return;

        let timeoutId = null;

        const onScroll = () => {
            if (timeoutId) return; // Ngăn chặn scroll liên tục

            timeoutId = setTimeout(() => {
                timeoutId = null;
                if (container.scrollTop + container.clientHeight >= container.scrollHeight - 10) {
                    setPage((prev) => prev + 1);
                }
            }, 300); // Giảm số lần gọi API
        };

        container.addEventListener("scroll", onScroll);
        return () => {
            container.removeEventListener("scroll", onScroll);
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, []);

    const totalCount = useMemo(() => {
        const keys = Object.keys(filter);
        const pairs = [["start_date", "end_date"], ["start_updated", "end_updated"]];

        return keys.reduce((total, key, index, arr) => {
            if (pairs.some(([start, end]) => key === end && arr.includes(start))) {
                return total;
            }
            return total + 1;
        }, 0);
    }, [filter]);

    return (
        <div style={{ display: "flex", flexDirection: "column", padding: "10px 5px" }}>
            <div>
        <span style={{ fontSize: "18px", fontWeight: "bold", padding: "0 5px", color: "#01638d" }}>
          {"Danh sách liên hệ"}
        </span>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        margin: "7px 0 0 7px",
                    }}
                >
                    <Select
                        defaultValue={"all"}
                        size={"small"}
                        onChange={(value) => setType(value)}
                        placeholder="Chọn trạng thái"
                        style={{ width: "150px", height: "30px" }}
                        options={TYPE_CONTACT.map((item) => ({ value: item.value, label: item.name }))}
                    />
                    <div role='presentation' onClick={() => setOpenFilter(true)} style={{padding: '4px'}}>
                        {!!totalCount && <span style={{color: "#01638d", fontSize: "12px"}}>{`(${totalCount})`}</span>}
                        <FilterOutlined style={{fontSize: '18px', color: "#01638d"}}/>
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                        padding: "7px 0",
                        justifyContent: "space-between",
                    }}
                >
                    <div style={{ display: "flex", flexDirection: "row", fontSize: "12px" }}>
                        <div style={{ padding: "0 5px", color: "#007BFF" }}>
                            <strong>Tổng:</strong> {total}
                        </div>
                        <div style={{ padding: "0 5px", color: "#28A745" }}>
                            <strong>Mới:</strong> {statistical?.status_new || 0}
                        </div>
                        <div style={{ padding: "0 5px", color: "#DC3545" }}>
                            <strong>Còn lại:</strong> {statistical?.status_call || 0}
                        </div>
                    </div>
                </div>
            </div>
            <List
                dataSource={dataContact}
                renderItem={(item) => (
                    <List.Item onClick={() => setIsIdDrawerContactDetail(item.code_contact)}>
                        <List.Item.Meta
                            avatar={<Avatar icon={<PhoneOutlined />} style={{ backgroundColor: "transparent", color: "#01638d", fontSize: 18 }} />}
                            title={<div style={{ color: item.type === "missed" ? "red" : "black" }}>{item.contact_name || maskPhoneNumber(item.phone)}</div>}
                            description={`${item.plate} | ${item.vehicles_brand} | ${item.vehicles_type}`}
                        />
                        <div>{item.date}</div>
                    </List.Item>
                )}
            />
            {loading && <Spin style={{ display: "block", textAlign: "center", margin: "10px 0" }} />}
            {openFilter && <Filter onCancel={() => setOpenFilter(false)} openFilter={openFilter} filter={filter} setFilter={setFilter} />}
            {!!isIdDrawerContactDetail && (
                <ContactCustomer
                    isIdDrawerContactDetail={isIdDrawerContactDetail}
                    contactId={isIdDrawerContactDetail}
                    onCancel={() => setIsIdDrawerContactDetail(null)}
                />
            )}
        </div>
    );
}

export default Index;
