import React, {useEffect, useRef, useState} from 'react';
import dayjs from "dayjs";
import {CloseOutlined, LoadingOutlined} from "@ant-design/icons"
import {useDispatch, useSelector} from "react-redux";
import {Col, ConfigProvider, DatePicker, Form, Input, Row, Select, Spin} from "antd";

import {INTENDED_USE} from "../../index";
import {
    EXPIRY_DATE, formatAddress,
    formatCurrency,
    gifts,
    maskPhoneNumber,
    statusCall,
    validateNoLettersOrSpecialChars
} from "../../components/ContactCustomer";
import HRService from "src/services/HumanResource";

import CRMService from "src/services/CRMService";
import {setListProvince} from "src/redux/hr";
import {TextTileStyle} from "../../../../HumanResource/styled";
import IconBase from "src/components/IconBase";
import {FormItemStyle} from "../../common/styled";
import {removeVietnameseTones} from "src/lib/stringsUtils";
import DrawerCustom from "src/components/DrawerCustom";
import Calling from "./Calling";
import Button from "src/components/MyButton/Button";
import Notice from "src/components/Notice";
import STORAGE from "src/lib/storage";
import {updateStatus} from "src/redux/crm";

Index.propTypes = {
    
};

function Index(props) {
    const { onCancel, contactId, isIdDrawerContactDetail } = props

    const [dataContact, setDataContact] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isPrioritize, setIsPrioritize] = useState(false)
    const [isFetchedPrioritize, setIsFetchedPrioritize] = useState(false);
    const [statusResult, setStatusResult] = useState(1);

    const { listProvince } = useSelector((state) => state.hr);
    const [district, setDistrict] = useState([])
    const [ward, setWard] = useState([])

    const permissions = localStorage.getItem(STORAGE.PERMISSIONS) || '';

    const shippingDataRef = useRef(null);
    const dispatch = useDispatch()

    const [form] = Form.useForm()
    const [orderForm] = Form.useForm()
    const [infoCarForm] = Form.useForm()

    const getProvince = () => {
        if (listProvince.length > 0) return
        CRMService.getProvince()
            .then(res => {
                dispatch(setListProvince(res?.success))
                // setProvinces(res?.success);
            })
            .catch(err => console.error('get province err', err))
    }

    const getDistrict = (provinceId) => {
        CRMService.getDistrict({province_id: provinceId})
            .then(res => {
                setDistrict(res?.success);
            })
            .catch(err => console.error('get district err', err))
    }

    const getWard = (districtId) => {
        CRMService.getWard({district_id: districtId})
            .then(res => {
                setWard(res?.success);
            })
            .catch(err => console.error('get ward err', err))
    }

    const handleFillDataOrder = (province_id, district_id, ward_id) => {
        const data = [
            { id: province_id, action: getProvince, key: 'province_id', nextAction: getDistrict },
            { id: district_id, action: () => {}, key: 'district_id', parentId: province_id, nextAction: getWard },
            { id: ward_id, action: () => {}, key: 'ward_id', parentId: district_id }
        ];

        data.forEach(({ id, action, key, parentId, nextAction }) => {
            if (id) {
                action(parentId);
                orderForm.setFieldsValue({ [key]: id.toString() });
                if (nextAction) nextAction(id);
            }
        });
    };

    const getTotalPrice = (duration = 12, isOrderInfo = false) => {
        const payload = {
            type: dataContact?.vehicle_type,
            load_capacity: Number(dataContact?.load_capacity) || 0,
            number_of_seats: Number(dataContact?.number_of_seats),
            usage_purpose: dataContact?.usage_purpose,
            duration
        }
        CRMService.totalPrice(payload)
            .then(res => {
                const { message } = res
                !isOrderInfo && setIsPrioritize(true)
                if (isOrderInfo) {
                    orderForm.setFieldsValue({
                        price: formatCurrency(message?.fee_including_vat?.toString()),
                    })
                } else {
                    form.setFieldsValue({
                        price_prioritize: formatCurrency(message?.fee_including_vat?.toString()),
                        price_discount_20: formatCurrency(message?.discount_20?.toString()),
                        price_discount_25: formatCurrency(message?.discount_25?.toString()),
                        price_discount_30: formatCurrency(message?.discount_30?.toString()),
                    })
                }
            })
            .catch(err => {
                !isOrderInfo && setIsPrioritize(false)
                console.error("err", err)
            })
            .finally(() => {
                !isOrderInfo && setIsFetchedPrioritize(true)
            })
    }

    useEffect(() => {
        if (statusResult === 4) {
            getTotalPrice(12, true)
        }
    }, [statusResult])

    useEffect(() => {
        const fetchData = async () => {
            if (Object.keys(dataContact).length === 0 && contactId) {
                try {
                    const res = await HRService.getContactDetail(contactId)
                    const { success } = res
                    const { shipping, data_price } = success

                    setDataContact(success)
                    infoCarForm.setFieldsValue({
                        time_extend: success.renewal_date ? dayjs(success.renewal_date) : undefined,
                        type_verhicle: success.vehicle_type,
                        brand: success.brand,
                        license_plate: success.license_plate,
                        target_use: INTENDED_USE[success.usage_purpose]?.title || '',
                        // number_seat: success.number_of_seats,
                        payload_number_seat: `${success.number_of_seats} / ${success.load_capacity || '...'}`,
                        machine_number: success.engine_number,
                        frame_number: success.chassis_number,
                        name: success.full_name,
                        phone_number: maskPhoneNumber(success.phone_number),
                        address: success.address,
                        province: success.provinces?.title,
                        price_info: success?.price ? formatCurrency(parseFloat(success?.price)?.toString()) : '',
                        shipping_address_info: success.shipping_address,
                    })

                    if (data_price && Object.keys(data_price).length > 0) {
                        form.setFieldsValue({
                            price_prioritize: formatCurrency(data_price?.fee_including_vat?.toString()),
                            price_discount_20: formatCurrency(data_price?.discount_20?.toString()),
                            price_discount_25: formatCurrency(data_price?.discount_25?.toString()),
                            price_discount_30: formatCurrency(data_price?.discount_30?.toString()),
                        })
                        setIsPrioritize(true)
                    }

                    shippingDataRef.current = shipping
                    if (success.callback_date) {
                        const now = dayjs();
                        const daysDiff = dayjs(success.callback_date).diff(now, 'day') + 1;
                        orderForm.setFieldsValue({
                            callbackDay: daysDiff.toString(),
                            callback_date: success.callback_date ? dayjs(success.callback_date) : undefined,
                        });
                    }
                    orderForm.setFieldsValue({
                        status_call: success.status,
                        desc: success.desc,
                    })
                    setStatusResult(success.status)
                } catch (err) {
                    console.log('Error fetching contact detail:', err)
                }
                finally {
                    setIsFetchedPrioritize(true)
                }
            }

            if (shippingDataRef.current && Object.keys(shippingDataRef.current).length > 0 && statusResult === 4) {
                const { province_id, district_id, ward_id } = shippingDataRef.current
                handleFillDataOrder(province_id, district_id, ward_id)
            }
        }

        fetchData().then()
    }, [contactId, statusResult])

    const createOrder = (payload) => {
        HRService.orderCreate(payload)
            .then(res => {
                onCancel();
                dispatch(updateStatus({contactId, status: 4}))
                // getListContact();
                Notice({ msg: "Tạo đơn hàng thành công!", isSuccess: true })
            })
            .catch(err => {
                Notice({ msg: "Tạo đơn hàng thất bại!", isSuccess: false })
            })
            .finally(() => setIsLoading(false))
    };

    const updateContact = (contactId, updatePayload) => {
        setIsLoading(true);
        HRService.updateContactStatus(contactId, updatePayload)
            .then(async res => {
                if (Number(statusResult) !== 4) {
                    Notice({ msg: "Cập nhật thành công!", isSuccess: true })
                    dispatch(updateStatus({contactId, ...updatePayload}))
                    // getListContact()
                    onCancel()
                    return
                }
                try {
                    const customerId = res?.success?.customer_id
                    const orderValues = orderForm.getFieldsValue()
                    const payload = {
                        ...orderValues,
                        customer_id: customerId,
                        duration: orderValues.duration.toString(),
                        start_date: dayjs(orderValues.start_date).format("YYYY-MM-DD"),
                        end_date: dayjs(orderValues.expiry_date).format("YYYY-MM-DD"),
                        price: orderValues?.price?.replace(/,/g, ''),
                        price_discount: orderValues?.price_discount?.replace(/,/g, ''),
                        contact_id: contactId,
                        province_id: Number(orderValues.province_id),
                        district_id: Number(orderValues.district_id),
                        ward_id: Number(orderValues.ward_id),
                        home: orderValues.shipping_address
                    };
                    delete payload.expiry_date
                    createOrder(payload)
                } catch (err) {
                    Notice({ msg: 'Đã xảy ra lỗi. Vui lòng liên hệ QTV', isSuccess: false })
                    console.error('OBJ ERR:', err);
                }
            })
            .catch(err => {
                Notice({ msg: 'Liên hệ cần được assign', isSuccess: false })
                console.error('update status err', err)
            })
            .finally(() => Number(statusResult) !== 4 && setIsLoading(false))
    }

    const handleChangeMoney = (e, field) => {
        const { value } = e.target;
        const formattedValue = formatCurrency(value);
        orderForm.setFieldsValue({ [field]: formattedValue});
    }

    const handleSubmit = async () => {
        if (!permissions.includes("contact.updateStatus")) {
            Notice({ msg: "Bạn không có quyền thực hiện hành động này!", isSuccess: false })
            return
        }

        try {
            const values = await orderForm.validateFields()
            const payload = { status: values.status_call, desc: values.desc }
            if (statusResult === 2) {
                delete payload.callbackDay
                payload.callback_date = dayjs(values.callback_date).format('YYYY-MM-DD')
            }
            if (Number(statusResult) === 4) delete payload.desc;
            await updateContact(contactId, payload);
        } catch (err) {
            form.scrollToField('price_discount', {
                behavior: 'smooth',
                block: 'center',
            })
            console.error("form error:", err)
        }
    };

    return (
        <DrawerCustom
            headerStyle={{ padding: "14px 7px" }}
            title={<span style={{ color: "#01638d" }}>Liên hệ</span>}
            bodyStyle={{ paddingTop: 0 }}
            closeIcon={<CloseOutlined style={{ color: "#01638d" }} />}
            open={!!isIdDrawerContactDetail}
            onClose={onCancel}
            size="full"
            extra={<Calling phoneNumber={dataContact?.phone_number} />}
        >
            <div style={{ marginBottom: "40px" }}>
                <ConfigProvider
                    theme={{
                        token: {
                            colorTextDisabled: "black",
                        },
                        components: {
                            Form: {
                                verticalLabelPadding: 0,
                                itemMarginBottom: 0
                            }
                        }
                    }}
                >
                    <Form
                        size={"small"}
                        form={infoCarForm}
                    >
                        <TextTileStyle style={{ color: "#01638d", borderBottom: '1px solid #01638d' }}>Thông tin xe</TextTileStyle>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item name="time_extend" label="Ngày gia hạn:">
                                    <DatePicker suffixIcon="" disabled format="DD/MM/YYYY" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="license_plate" label="Biển kiểm soát:">
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="type_verhicle" label="Loại xe:">
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="brand" label="Nhãn hiệu:">
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                            {/*<Col span={12}>*/}
                            {/*    <Form.Item name="frame_number" label="Số khung:">*/}
                            {/*        <Input disabled />*/}
                            {/*    </Form.Item>*/}
                            {/*</Col>*/}
                            {/*<Col span={12}>*/}
                            {/*    <Form.Item name="machine_number" label="Số máy:">*/}
                            {/*        <Input disabled />*/}
                            {/*    </Form.Item>*/}
                            {/*</Col>*/}
                            <Col span={12}>
                                <Form.Item name="payload_number_seat" label="Số chỗ/Trọng tải:">
                                    <Input maxLength={255} disabled />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="target_use" label="Mục đích sử dụng:">
                                    <Input maxLength={255} disabled />
                                </Form.Item>
                            </Col>
                        </Row>
                        <TextTileStyle style={{ color: "#01638d", borderBottom: '1px solid #01638d' }}>Thông tin khách hàng</TextTileStyle>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item name="name" label="Tên khách hàng">
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="province" label="Tỉnh">
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item name="address" label="Địa chỉ">
                                    <span>{dataContact?.address}</span>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="phone_number"
                                    label="Số điện thoại"
                                    valuePropName="phone_number"
                                >
                                    <div style={{ display: 'flex' }}>
                                        <Form.Item
                                            name="phone_number"
                                            noStyle
                                        >
                                            <Input disabled />
                                        </Form.Item>
                                        <div
                                            onClick={() => {
                                                const url = `https://zalo.me/${dataContact?.phone_number}`;
                                                window.open(url, "_blank", "width=500,height=600");
                                            }}
                                            style={{ cursor: 'pointer', marginLeft: '5px' }}
                                        >
                                            <IconBase size='larger' name='zalo' />
                                        </div>
                                    </div>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <Form
                        form={form}
                        layout="horizontal"
                        initialValues={{ duration_prioritize: 12 }}
                    >
                        <TextTileStyle style={{ color: "#01638d", borderBottom: '1px solid #01638d' }}>Thông tin ưu đãi</TextTileStyle>
                        {isFetchedPrioritize ? (
                            isPrioritize ? (
                                <>
                                    <Row gutter={10}>
                                        <Col span={12}>
                                            <Form.Item
                                                label="T/G bảo hiểm"
                                                name="duration_prioritize"
                                            >
                                                <Select onChange={value => getTotalPrice(value)}>
                                                    {EXPIRY_DATE.map(item => (
                                                        <Select.Option key={item.id?.toString()} value={item.ID}>
                                                            {item.title}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label="Giá niêm yết"
                                                name="price_prioritize"
                                            >
                                                <Input suffix="đ" disabled={true} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={10}>
                                        <Col span={8}>
                                            <Form.Item
                                                label="C/K (20%)"
                                                name="price_discount_20"
                                            >
                                                <Input suffix="đ" disabled={true} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                label="C/K (25%)"
                                                name="price_discount_25"
                                            >
                                                <Input suffix="đ" disabled={true} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                label="C/K (30%)"
                                                name="price_discount_30"
                                                labelCol={{ span: 24 }}
                                                wrapperCol={{ span: 24 }}
                                            >
                                                <Input suffix="đ" disabled={true} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </>
                            ) : (
                                <div>Chưa có ưu đãi cho liên hệ này</div>
                            )
                        ) : (
                            <p>Đang tải...</p>
                        )}
                    </Form>
                    <Form form={orderForm} layout="vertical" initialValues={{ duration: 12 }}>
                        <TextTileStyle style={{ color: "#01638d", borderBottom: '1px solid #01638d' }}>Kết quả</TextTileStyle>
                        <Row gutter={16}>
                            <Col>
                                <Form.Item
                                    name="status_call"
                                    label="Trạng thái cuộc gọi"
                                    rules={[
                                        { required: true, message: 'Chọn trạng thái.'}
                                    ]}
                                >
                                    <Select
                                        style={{ height: 'unset' }}
                                        placeholder="Chọn trạng thái"
                                        onChange={v => {
                                            setStatusResult(v)
                                            if (v === 4) {
                                                orderForm.scrollToField('shipping_address', {
                                                    behavior: 'smooth',
                                                    block: 'center',
                                                })
                                            }
                                        }}
                                        allowClear
                                    >
                                        {statusCall.map(item => (
                                            <Select.Option key={item.id} value={item.id} >
                                                {item.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={10}>
                            {
                                statusResult === 2 && (
                                    <>
                                        <Col span={12}>
                                            <Form.Item
                                                name="callback_date"
                                                label="Ngày hẹn"
                                                rules={[
                                                    { required: true, message: 'Chọn ngày hẹn.'}
                                                ]}
                                            >
                                                <DatePicker
                                                    suffixIcon=""
                                                    inputReadOnly
                                                    format="DD/MM/YYYY"
                                                    onChange={(date) => {
                                                        if (date) {
                                                            const now = dayjs();
                                                            const daysDiff = date.diff(now, 'day') + 1;
                                                            orderForm.setFieldsValue({ callbackDay: daysDiff.toString() });
                                                        }
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                name="callbackDay"
                                                label="Hẹn lại sau"
                                                inputMode="numeric"
                                                rules={[
                                                    {
                                                        pattern: /^[0-9,]*$/,
                                                        message: `Nhập số ngày hợp lệ.`,
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    maxLength={3}
                                                    suffix='ngày'
                                                    onChange={(e) => {
                                                        const days = parseInt(e.target.value, 10);
                                                        if (!isNaN(days)) {
                                                            const newDate = dayjs().add(days, 'day');
                                                            orderForm.setFieldsValue({ callback_date: dayjs(newDate) });
                                                        }
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </>
                                )
                            }
                        </Row>
                        <Row>
                            {Number(statusResult) !== 4 && (
                                <Col span={24}>
                                    <Form.Item name="desc" label="Ghi chú">
                                        <Input.TextArea rows={2}/>
                                    </Form.Item>
                                </Col>
                            )}
                        </Row>
                        {Number(statusResult) === 4 && (
                            <>
                                <TextTileStyle style={{ color: "#01638d", borderBottom: '1px solid #01638d' }}>Thông tin đơn hàng</TextTileStyle>
                                <Row gutter={10}>
                                    <Col span={8}>
                                        <Form.Item
                                            label="Ngày bắt đầu"
                                            name="start_date"
                                            rules={[
                                                { required: true, message: "Nhập ngày!" },
                                            ]}
                                        >
                                            <DatePicker
                                                inputReadOnly
                                                suffixIcon=""
                                                allowClear={false}
                                                format="DD/MM/YYYY"
                                                onChange={(val) => {
                                                    const duration = orderForm.getFieldValue('duration');
                                                    if(duration === undefined) return
                                                    const expiry_date = dayjs(val).add(duration, 'month');
                                                    orderForm.setFieldsValue({ expiry_date })
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label="Thời gian"
                                            name="duration"
                                        >
                                            <Select
                                                defaultValue={12}
                                                onChange={(val) => {
                                                    const start_date = orderForm.getFieldValue('start_date');
                                                    if(start_date === undefined) return
                                                    const expiry_date = dayjs(start_date).add(val, 'month');
                                                    orderForm.setFieldsValue({ expiry_date })
                                                    getTotalPrice(val, true)
                                                }}
                                            >
                                                {EXPIRY_DATE.map(item => (
                                                    <Select.Option key={item.id?.toString()} value={item.ID}>
                                                        {item.title}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label="Ngày hết hạn"
                                            name="expiry_date"
                                        >
                                            <DatePicker suffixIcon="" disabled={true} format="DD/MM/YYYY" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={8}>
                                        <Form.Item
                                            label="Giá niêm yết"
                                            name="price"
                                            rules={[
                                                { required: true, message: 'Nhập giá!' },
                                                { validator: validateNoLettersOrSpecialChars }
                                            ]}
                                        >
                                            <Input disabled={isPrioritize} suffix="đ" onChange={(e) => handleChangeMoney(e, 'price')} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label="Giá chiết khấu"
                                            name="price_discount"
                                            rules={[
                                                { required: true, message: 'Nhập giá!' },
                                                { validator: validateNoLettersOrSpecialChars },
                                            ]}
                                        >
                                            <Input inputMode="numeric" suffix="đ" onChange={(e) => handleChangeMoney(e, 'price_discount')} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label="Quà tặng"
                                            name="gift_type"
                                        >
                                            <Select allowClear>
                                                {gifts.map(item => (
                                                    <Select.Option key={item.id?.toString()} value={item.ID}>
                                                        {item.title}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}></Col>
                                </Row>
                                <Row gutter={10}>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Tên chủ xe"
                                            name="name"
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Số điện thoại"
                                            name="phone"
                                            rules={[
                                                { required: true, message: "Nhập số điện thoại!" },
                                            ]}
                                        >
                                            <Input inputMode="numeric" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} />
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <FormItemStyle
                                            label="Tỉnh thành"
                                            name="province_id"
                                            rules={[{ required: true, message: "Chọn tỉnh thành!" }]}
                                        >
                                            <Select
                                                notFoundContent={listProvince.length === 0 ? (
                                                    <div style={{textAlign: 'center', padding: '10px'}}>
                                                        <Spin size='small' color='gray' />
                                                    </div>
                                                ) : null}
                                                onClick={getProvince}
                                                showSearch
                                                placeholder="Nhập tỉnh thành"
                                                optionFilterProp="children"
                                                filterOption={(input, option) => {
                                                    const source = removeVietnameseTones(option.children)
                                                    const target = removeVietnameseTones(input)
                                                    return source.includes(target)
                                                }}
                                                onChange={value => {
                                                    orderForm.setFieldsValue({ province_id: value });
                                                    orderForm.resetFields(['district_id', 'ward_id'])
                                                    getDistrict(value)
                                                }}
                                            >
                                                {listProvince?.length > 0 &&
                                                    listProvince.map(value => (
                                                        <Select.Option key={value.title} value={value?.ID?.toString()}>
                                                            {formatAddress(value.title)}
                                                        </Select.Option>
                                                    ))}
                                            </Select>
                                        </FormItemStyle>
                                    </Col>
                                    <Col span={12}>
                                        <FormItemStyle
                                            label="Quận huyện"
                                            name="district_id"
                                            rules={[{ required: true, message: "Chọn quận huyện!" }]}
                                        >
                                            <Select
                                                disabled={!orderForm.getFieldValue('province_id')}
                                                showSearch
                                                placeholder="Nhập quận huyện"
                                                optionFilterProp="children"
                                                filterOption={(input, option) => {
                                                    const source = removeVietnameseTones(option.children)
                                                    const target = removeVietnameseTones(input)
                                                    return source.includes(target)
                                                }}
                                                onChange={value => {
                                                    orderForm.setFieldsValue({ district_id: value })
                                                    orderForm.resetFields(['ward_id'])
                                                    getWard(value)
                                                }}
                                            >
                                                {district.length > 0 &&
                                                    district.map(value => (
                                                        <Select.Option key={value.title} value={value?.ID?.toString()}>
                                                            {formatAddress(value.title)}
                                                        </Select.Option>
                                                    ))}
                                            </Select>
                                        </FormItemStyle>
                                    </Col>
                                    <Col span={12}>
                                        <FormItemStyle
                                            label="Xã phường"
                                            name="ward_id"
                                            rules={[{ required: true, message: "Chọn xã phường!" }]}
                                        >
                                            <Select
                                                disabled={!orderForm.getFieldValue('district_id')}
                                                showSearch
                                                placeholder="Nhập xã phường"
                                                optionFilterProp="children"
                                                filterOption={(input, option) => {
                                                    const source = removeVietnameseTones(option.children)
                                                    const target = removeVietnameseTones(input)
                                                    return source.includes(target)
                                                }}
                                                onChange={value => {
                                                    form.setFieldsValue({ ward_id: value.toString() })
                                                }}
                                            >
                                                {ward.length > 0 &&
                                                    ward.map(value => (
                                                        <Select.Option key={value.title} value={value?.ID?.toString()}>
                                                            {formatAddress(value.title)}
                                                        </Select.Option>
                                                    ))}
                                            </Select>
                                        </FormItemStyle>
                                    </Col>
                                    <Col span={6} />
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            label={
                                                <span>Địa chỉ nhận hàng{" "}
                                                    <span style={{fontSize: "12px", fontStyle: "italic", color: 'gray' }}>(Số nhà, thôn, xóm ...)</span></span>
                                            }
                                            name="shipping_address"
                                            rules={[{required: true, message: "Nhập địa chỉ nhận hàng!"}]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} />
                                </Row>
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Form.Item
                                            label="Ghi chú"
                                            name="desc"
                                        >
                                            <Input.TextArea rows={2} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Form>
                </ConfigProvider>
                <Button
                    disabled={(dataContact?.status === 6 && statusResult === 6) || isLoading}
                    style={{ minWidth: "76px", float: 'right' }}
                    btnType="primary"
                    className="btn-hover-shadow"
                    onClick={handleSubmit}
                >
                    {isLoading ? (
                        <LoadingOutlined />
                    ) : Number(statusResult) === 4 ? (
                        "Tạo đơn"
                    ) : (
                        "Cập nhật"
                    )}
                </Button>
            </div>
        </DrawerCustom>
    );
}

export default Index;